<script setup lang="ts">
import seedrandom from 'seedrandom'

interface Props {
  /**
   * размер аватарки в десктопе
   */
  size: '30' | '40' | '60' | '100' // размер аватарки в десктопе
  /**
   * логин пользователя
   */
  name?: string
  /**
   * размер аватарки в мобилке
   */
  mobileSize?: '25' | '30' | '40' | '60' | '70' | '100'
  /**
   * изображение аватарки
   */
  img?: string
}

const props = withDefaults(defineProps<Props>(), {
  size: '60',
})

const getMobileSize = computed(() => {
  return props.mobileSize || props.size
})

const firstSign = ref(props.name?.[0])
const seed = seedrandom(firstSign.value)()
const color = ref(`hsl(${360 * seed}, 56%, 60%)`)
</script>

<template>
  <div
    class="color-avatar"
    :class="[`color-avatar_${size}`, `color-avatar_mobile-${getMobileSize}`]"
    :style="{ backgroundColor: img ? undefined : color }"
  >
    <ImgLazy
      v-if="img"
      :img-path="img"
      :alt="name"
      class="color-avatar__image"
    />
    <template v-else>
      {{ firstSign }}
    </template>
  </div>
</template>

<style lang="postcss" scoped>
.color-avatar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  background-position: center;
  background-size: contain;
  border-radius: 50%;

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image[lazy='loading'] {
    max-width: 100%;
    max-height: 100%;
    border: 1px solid #eeeeee;
    border-radius: 50%;
  }

  &_30 {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }

  &_40 {
    width: 40px;
    height: 40px;
    font-size: 15px;
  }

  &_60 {
    width: 60px;
    height: 60px;
    font-size: 23px;
  }

  &_100 {
    width: 100px;
    height: 100px;
    font-size: 23px;
  }

  @media (max-width: 800px) {

    &_mobile-25 {
      width: 25px;
      height: 25px;
      font-size: 12px;
    }

    &_mobile-30 {
      width: 30px;
      height: 30px;
      font-size: 13px;
    }

    &_mobile-40 {
      width: 40px;
      height: 40px;
      font-size: 15px;
    }

    &_mobile-70 {
      width: 70px;
      height: 70px;
    }
  }
}
</style>
